import * as React from "react";
import Layout from "../../components/Layout";
import FAQAccord from "../../components/GFlush";
import GeneralTab from "../../components/HTabs";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
// import Hero from "../../components/Hero";
import About from "../../components/About";
import Service from "../../components/Services";
import Document from "../../components/Document";
import Cta from "../../components/Cta";
import Package from "../../components/GSTTemp/Package";

export default function Accounting() {
  /* Slider */
  // const AccountingSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 30,
  //     background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
  //     header: `Best Accounting Services in India`,
  //     content: `TODAYFILINGS, India's largest accounting service in india, can assist you
  //       with your business.`,
  //     image: "/imgs/business/sliders/nidhi-slider.png",
  //     alt_tag: "Best Online Advisory Service for  Accounting in Hosur",
  //   },
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 30,
  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     header: `Online Advisory Service for Accounting`,
  //     content: `Best Accounting Advisory Service Platform`,
  //     image: "/imgs/business/sliders/producer-slider.png",
  //     alt_tag: "Best Online Advisory Service for accounting near Bangalore",
  //   },

  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 80,
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `Advisory Service for Accounting in Hosur`,
  //     content: `One the top most success rated Advisory Service for Accounting  in Hosur`,
  //     image: "/imgs/business/sliders/pvt.png",
  //     alt_tag: "Best Online Advisory Service for accounting in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Accounting Service`,
    buyBtnLink: `#pricing-buy`,
    price: `4999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data Without Form*/
  const AccountingAboutData = {
    title: `Accounting Services`,
    sub_title: `Largest Accounting Service Business in India`,
    paragraph: `Accounting services in India are among the most essential
    elements of a company because finance is what not only
    ensures the company's survival but also supports its
    development and progress. In its most basic form, accounting
    refers to a procedure that the entity should use to record
    and manage the financial records that are relevant to the
    firm.single department with numerous personnel will be hired
    and placed for handling such financial and accounting
    transactions in a large organization with a lot of
    accounting transactions.`,
    header: `Why TODAYFILINGS Accounting service?`,
    points: [
      `TODAYFILINGS is known as the top Accounting Service Provider in
      India, with over 20 years of Accounting Services
      experience.`,
      `100% Safe and secure`,
      `Affordable price`,
    ],
  };

  /* Service Data */
  const AccountingServiceData = {
    header: `Dependable one-stop location for accounting services`,
    content: ` Accounting services can be a more involved and complicated
    undertaking, making their simplicity essential. It is not
    always simple to find the proper candidates, hire them, and
    train them.`,
    image: '/imgs/business-plan.png',
    alt_img: 'TODAYFILINGS Accounting Services',
    points: [
      `Services for Small Businesses in Accounting`,
      `Accounting Services for New Businesses`,
      `Accounting for Non-Profit Organizations`,
      `Restaurant accounting services`,
    ],
  };
  /* FAQ data */
  var FAQ_data = [
    {
      header: 'What is an accounting?',
      body: [
        {
          content: `Accounting is the measurement, processing, and dissemination of
          financial and other data concerning organizations and
          corporations.`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: 'What are the types of accounting?',
      body: [
        {
          content: `Corporate Accounting`,
          icon: true,
        },
        {
          content: `Public Accounting`,
          icon: true,
        },
        {
          content: `Government Accounting`,
          icon: true,
        },
      ],
    },
  ];

  // tabData
  const AccountingTabsData = {
    id: 'features',
    heading: 'Accounting Services Process',
    paragraph: `The accounting department is one of the most important parts of
    a company's organizational structure. our consulting services
    are,`,
    body: [
      {
        tab_title: 'Virtual Accounting',
        tab_content: [
          {
            content_title: `Services for virtual accounting`,
            content_paragraph: ``,
            points: [
              `The breadth and value of your business will probably rise
            along with it as it develops overall as a result of its
            expansion. Virtual accounting is efficient, flexible, and
            cost-effective.`,
              `One such accounting service is virtual accounting, which
            helps businesses manage their accounting functions or the
            overall financial picture more efficiently with the help
            of qualified accountants who operate remotely, making it
            comfortable for both you and the system.`,
            ],
          },
        ],
        content_img: '/imgs/assited/virtual-accounting.png',
        alt_img: 'TODAYFILINGS Virtual Accounting Service',
      },
      {
        tab_title: 'Professional Accounting',
        tab_content: [
          {
            content_title: 'Services for Professional Accounting',
            content_paragraph: ``,
            points: [
              `To develop, curate, and carry out the accounting services
            efficiently, a team of qualified Chartered Accountants and
            Company Secretaries who are not only members of the
            national accounting body but also possess the expertise
            knowledge will be needed.`,
              `At TODAYFILINGS, we have a good team of skilled and knowledgeable
            specialists who can provide you with individualized
            service in addition to their experience with audits,
            financial statement management, and cash statements.`,
            ],
          },
        ],
        content_img: '/imgs/assited/professional-accounting.png',
        alt_img: 'TODAYFILINGS Professional Accounting Service',
      },
      {
        tab_title: 'Tax Planning',
        tab_content: [
          {
            content_title: 'Tax Consulting and Planning',
            content_paragraph: `Maximizing a client's tax efficiency is the main objective
          of tax planning. With proper tax planning, all financial
          activities will operate in the most tax-effective manner
          possible, making them not only efficient but also more
          economical.`,
            points: [],
          },
        ],
        content_img: '/imgs/registration/tax/tax-img-slider.png',
        alt_img: 'TODAYFILINGS Tax Planing  Service',
      },
      {
        tab_title: 'Customized Consulting',
        tab_content: [
          {
            content_title: '',
            content_paragraph: `There is no room for anything less than the best when it
          comes to accounting consulting services. Our staff will
          work with you to better analyze and comprehend your
          financial data so that you can make business decisions
          that will improve and grow the company. We'll help you
          project the financial health of your business, which will
          also enable you to plan the next phase of expansion.`,
            points: [],
          },
        ],
        content_img: '/imgs/assited/cfo-accounting.png',
        alt_img: 'TODAYFILINGS  Accounting Customized Consulting Service',
      },
    ],
  };

  /* Document Section Data */
  const AccountingAuditDocData = {
    id: 'acc-doc',
    heading: `Accounting Services Offered by TODAYFILINGS`,
    sub_heading: `One of the crucial components of a company's organizational
      structure is the accounting department. All businesses, no
      matter how little, how big, or how new, need an accounting
      department. It can be utilized to manage the account system,
      which will help the company grow steadily.`,
    doc_contents: [
      {
        col: `col-lg-4 col-md-6`,
        delay: '100',
        img: `/imgs/icons/it/fin-accounting.png`,
        alt_tag: `Financial Accounting`,
        header: `Financial Accounting`,
        description: `This service focuses mostly on summarizing, doing analysis,
              preparing financial statements, and reporting transactions
              relevant to the entity's financial element.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '200',
        img: `/imgs/icons/it/management-acc.png`,
        alt_tag: `Management Accounting`,
        header: `Management Accounting`,
        description: `This service focuses mostly on summarizing, doing analysis,
              preparing financial statements, and reporting transactions
              relevant to the entity's financial element.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '300',
        img: `/imgs/icons/it/tax-acc.png`,
        alt_tag: `Tax Accounting`,
        header: `Tax Accounting`,
        description: `The accounting guidelines control a company's tax
              liabilities. They are governed by legislation and are
              distinct from standard accounting concepts.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '400',
        img: `/imgs/icons/it/cost-acc.png`,
        alt_tag: `Cost Accounting`,
        header: `Cost Accounting`,
        description: `Method for allocating process costs and putting cost-cutting
              measures in action, can increase a company's costs will go
              down and profits will either go up or down.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '500',
        img: `/imgs/icons/it/compliances.png`,
        alt_tag: `Statutory Compliances`,
        header: `Statutory Compliances`,
        description: `Our accounting team is knowledgeable with the tax
              deductions, ESI Fund, PF Deductions, compliance with minimum
              wage requirements, gratuity, professional taxes.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '600',
        img: `/imgs/icons/it/payroll.png`,
        alt_tag: `Auditing and Payroll`,
        header: `Auditing and Payroll`,
        description: `We support with payroll tax filing, financial condition
              disclosure to all parties interested in the matter,
              accurately calculating and documenting deductions, and
              benefits.`,
      },
    ],
  };
  return (
    <div>
      <Seo
        title='Best Accounting Services in India'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={AccountingSlider} /> */}

          <Package packageData={PackageCompData} />
          <About about_data={AccountingAboutData} />

          <Counter />

          <Service item={AccountingServiceData} />
          <Document docSecData={AccountingAuditDocData} />
          <GeneralTab HTabsData={AccountingTabsData} />
          <Cta />
          <FAQAccord items={FAQ_data} />
        </main>
      </Layout>
    </div>
  );
}
